import React from 'react'
import styled from 'styled-components/macro'
import {rem} from 'polished'
import {useAbortableFetch} from 'core/hooks'
import {useInView} from 'react-intersection-observer'
import {media, scale} from 'core/theme'
import {ActionButton} from 'modules/Article/elements'
import {motion} from 'framer-motion'

const Wrapper = styled.div`
  text-align: center;
  margin-bottom: ${rem(scale[6])};

  &:empty {
    min-height: ${rem(62)};
  }

  ${media.tablet`
    margin-bottom: ${rem(scale[10])};
  `};
`

const variants = {
  initial: {
    opacity: 0,
    y: 20,
  },
  enter: {
    opacity: 1,
    y: 0,
    transition: {type: 'spring', stiffness: 200, damping: 15, delay: 0.5},
  },
}

// NOTE: Deprecated. Use select by year for news archive.
function ViewMoreWithData({currentPage, onClick}) {
  const {data} = useAbortableFetch(`/api/pagination`)
  if (data) {
    const {totalPosts, totalPages} = data
    if (totalPages && totalPages > 1) {
      const currentlyLoaded = currentPage * 20
      const remainingPosts = totalPosts - currentlyLoaded
      return (
        <ActionButton
          as={motion.button}
          initial='initial'
          animate='enter'
          variants={variants}
          onClick={onClick}
        >
          {`View 20 more (${remainingPosts})`}
        </ActionButton>
      )
    }
  }
  return null
}

export default function ViewMore({currentPage, onClick}) {
  const [ref, inView] = useInView({
    rootMargin: '-10% 0%',
    triggerOnce: true,
  })

  return (
    <Wrapper ref={ref}>
      {inView && <ViewMoreWithData currentPage={currentPage} onClick={onClick} />}
    </Wrapper>
  )
}
