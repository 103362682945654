import React from 'react'
import {NavLink} from 'react-router-dom'
import styled from 'styled-components/macro'
import {rem} from 'polished'
import {motion, useTransform} from 'framer-motion'
import {ColorPresence, color, scale} from 'core/theme'

const StyledNav = styled(motion.nav)`
  ul {
    flex-direction: row;
    margin: 0 ${rem(-scale[2])};
  }

  li {
    margin: 0 ${rem(scale[2])};
  }

  a {
    color: inherit;
    margin: -0.5em;
    padding: 0.5em;
    text-decoration: none;

    &.active {
      position: relative;

      &::after {
        background-color: ${color.brand.yellow};
        border-radius: ${rem(4)};
        content: '';
        display: block;
        position: absolute;
        right: 0;
        bottom: -0.25em;
        left: 0;
        height: ${rem(4)};
      }
    }
  }
`

export default function MainNav({pages}) {
  const {color, saturation} = React.useContext(ColorPresence)
  const textColor = useTransform(saturation, [0, 1], ['#31589F', '#ffffff'])

  function resetColors() {
    color.set('#ffffff')
    saturation.set(0)
  }

  return (
    <StyledNav style={{color: textColor}}>
      <ul>
        {pages.map(page => (
          <li key={page.slug}>
            <NavLink to={page.url} onClick={() => resetColors()}>
              {page.title}
            </NavLink>
          </li>
        ))}
      </ul>
    </StyledNav>
  )
}
