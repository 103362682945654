import React from 'react'
import styled from 'styled-components/macro'
import {rem} from 'polished'
import {NavLink} from 'react-router-dom'
import {color, media, scale, breakpoints} from 'core/theme'
import {useMatchMedia} from 'core/hooks'
import {LanguageContext, useTranslation} from 'core/language'
import {ContentWidth, ExternalLink} from 'elements'
import {Icon} from 'modules/Article/elements'

const StyledFooter = styled.footer`
  background-color: ${color.text.default};
  color: ${color.white};
  font-size: ${rem(15)};
  overflow: hidden;
  margin-top: auto;
  padding: ${rem(scale[5])} 0;
  position: relative;

  a {
    color: inherit;
    display: inline-block;
    text-decoration: none;

    &[rel]:hover {
      text-decoration: underline;
    }
  }

  p {
    margin: 0;
  }

  ${media.tablet`
    padding: ${rem(scale[9])} 0;
  `};
`

const StyledNav = styled.nav`
  ul {
    flex-wrap: wrap;
    margin: ${rem(-scale[1])};
  }

  li {
    margin: ${rem(scale[1])};
  }

  a {
    color: inherit;
    margin: -0.5em;
    padding: 0.5em;
    text-decoration: none;

    &.active,
    &:hover {
      text-decoration: underline;
    }
  }

  ${media.tablet`
    ul {
      flex-direction: row;
      margin: ${rem(-scale[1])} ${rem(-scale[2])};
    }

    li {
      margin: ${rem(scale[1])} ${rem(scale[2])};
    }
  `};
`

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 ${rem(-scale[1])};

  ${media.tablet`
    margin: 0 ${rem(-scale[2])};
  `};
`

const Column = styled.div`
  margin: 0 ${rem(scale[1])};

  ${media.tablet`
    margin: 0 ${rem(scale[2])};
  `};
`

const TabletRowBottom = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 0 ${rem(-scale[2])};
`

const MobileRowBottom = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin: 0 ${rem(-scale[1])};
`

const ContactDetails = styled.p`
  > a + a {
    margin-top: ${rem(scale[1])};
  }
`

const InlineSpacer = styled.span`
  display: block;
  height: 0.5em;
`

const Copyright = styled.p`
  white-space: nowrap;

  ${media.tablet`
    text-align: right;
  `};
`

const SocialList = styled.ul`
  margin-right: ${rem(-scale[2])};
  margin-bottom: ${rem(-scale[2])};

  a {
    padding: ${rem(4)};
  }

  svg {
    width: ${rem(scale[5])};
    height: ${rem(scale[5])};
  }

  svg path {
    fill: ${color.white};
  }

  ${media.tablet`
    flex-direction: row;
    margin-right: ${rem(-scale[2])};
    margin-bottom: 0;

    svg {
      width: ${rem(scale[6])};
      height: ${rem(scale[6])};
    }
  `};
`

const Spacer = styled.div`
  padding-top: ${rem(scale[3])};

  ${media.tablet`
    padding-top: ${rem(scale[8])};
  `};
`

export default function Footer() {
  const {activeLang, defaultLang} = React.useContext(LanguageContext)
  const translate = useTranslation()

  const matchTablet = useMatchMedia(breakpoints.tablet)

  const pages = React.useMemo(
    () => [
      {
        slug: translate('par-mums'),
        title: translate('Par mums'),
      },
      {
        slug: translate('medijiem'),
        title: translate('Medijiem'),
      },
      {
        slug: translate('ricibas-kodekss'),
        title: translate('Rīcības kodekss'),
      },
      {
        slug: translate('privatuma-politika'),
        title: translate('Privātuma politika'),
      },
    ],
    [translate]
  )

  const langPath = activeLang === defaultLang ? '' : `/${activeLang}`

  const mainNav = React.useMemo(
    () => (
      <StyledNav>
        <ul>
          {pages.map(page => {
            return (
              <li key={page.slug}>
                <NavLink to={`${langPath}/${page.slug}`}>{page.title}</NavLink>
              </li>
            )
          })}
        </ul>
      </StyledNav>
    ),
    [pages, langPath]
  )

  const socialLinks = React.useMemo(() => {
    const links = [
      {
        icon: 'facebook',
        title: 'Facebook',
        url: 'https://www.facebook.com/FoodUnion',
      },
      {
        icon: 'instagram',
        title: 'Instagram',
        url: 'https://www.instagram.com/foodunionlv/',
      },
      {
        icon: 'twitter',
        title: 'Twitter',
        url: 'https://twitter.com/FoodUnion',
      },
      {
        icon: 'linkedin',
        title: 'LinkedIn',
        url: 'https://www.linkedin.com/company/food-union-group',
      },
      {
        icon: 'youtube',
        title: 'YouTube',
        url: 'https://www.youtube.com/user/FoodUnion',
      },
    ]
    return (
      <SocialList>
        {links.map(({title, url, icon}) => (
          <li key={title}>
            <ExternalLink title={title} url={url}>
              <Icon id={icon} />
            </ExternalLink>
          </li>
        ))}
      </SocialList>
    )
  }, [])

  const contactDetails = React.useMemo(
    () => (
      <ContactDetails>
        <ExternalLink url='https://goo.gl/maps/eNVLpBvwdbiCVjt28'>
          {translate('Bauskas iela 180')},
          <br />
          {translate('Rīga, LV-1004, Latvija')}
        </ExternalLink>
        <InlineSpacer />
        <ExternalLink url='tel:+371-800-01-110' target='_self'>
          (+371) 800-01-110
        </ExternalLink>
      </ContactDetails>
    ),
    [translate]
  )

  const copyright = React.useMemo(() => {
    const currentYear = new Date().getFullYear()
    return <Copyright>{`© Food Union Latvia ${currentYear}`}</Copyright>
  }, [])

  if (matchTablet) {
    return (
      <StyledFooter>
        <ContentWidth>
          <Flex>
            <Column>{contactDetails}</Column>
            <Column>{socialLinks}</Column>
          </Flex>
          <Spacer />
          <TabletRowBottom>
            <Column>{mainNav}</Column>
            <Column>{copyright}</Column>
          </TabletRowBottom>
        </ContentWidth>
      </StyledFooter>
    )
  }

  return (
    <StyledFooter>
      <ContentWidth>
        {mainNav}
        <Spacer />
        <MobileRowBottom>
          <Column>
            {contactDetails}
            <br />
            {copyright}
          </Column>
          <Column>{socialLinks}</Column>
        </MobileRowBottom>
      </ContentWidth>
    </StyledFooter>
  )
}
