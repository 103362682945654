import {breakpoints as breakpointDefaults} from './tokens/layout'

export const sortEntriesByValue = object =>
  Object.entries(object).sort((a, b) => a[1] - b[1])

export const mapBreakpoints = breakpoints =>
  breakpoints.reduce((array, [key, value], i) => {
    array[i] = value
    array[key] = value
    return array
  }, [])

export const breakpoints = mapBreakpoints(
  sortEntriesByValue(breakpointDefaults)
)

export const mediaQueries = breakpoints.map(
  px => `@media screen and (min-width: ${px / 16}em)`
)

export const guard = (value, condition) => (value === null ? null : condition)

export const useResponsiveValue = (input, parse, transform) => {
  if (input === undefined) {
    throw new TypeError(
      "You must provide an 'input' argument to useResponsiveValue."
    )
  }

  if (typeof transform !== 'function') {
    throw new TypeError(
      "You must provide a 'transform' argument of type function to useResponsiveValue."
    )
  }

  const parseFn = value => guard(value, parse ? parse(value) : value)
  const transformFn = value =>
    guard(value, transform && transform(value).trim())

  if (Array.isArray(input)) {
    return input
      .map(parseFn)
      .map(transformFn)
      .map((style, index) => {
        if (style === null) {
          // skip breakpoint
          return ''
        }
        if (index > 0) {
          const breakpoint = breakpoints[index - 1]
          return breakpoint && `${mediaQueries[index - 1]}{${style}}`
        }
        return style
      })
  }

  return transformFn(parseFn(input))
}
