import React from 'react'
import styled, {css} from 'styled-components/macro'
import {rem} from 'polished'
import {motion} from 'framer-motion'
import {media, scale} from 'core/theme'

const Wrapper = styled.div`
  margin: ${rem(scale[4])} 0 ${rem(scale[6])};

  ${media.tablet`
    margin: ${rem(scale[6])} 0 ${rem(scale[9])};
  `};
`

// FIXME: Refactor to grid only?
const GridWithFallback = styled(motion.ul)`
  > li + li {
    margin-top: ${rem(scale[3])};
  }

  ${media.mobile`
    flex-direction: row;
    flex-wrap: wrap;
    margin: ${rem(-scale[3] / 2)};

    > li {
      flex: 1;
      margin: ${rem(scale[3] / 2)};
      min-width: 15rem;
    }

    > li + li {
      margin-top: ${rem(scale[3] / 2)};
    }
  `};

  ${media.tablet`
    margin: ${rem(-scale[5] / 2)} ${rem(-scale[3] / 2)};

    > li {
      margin: ${rem(scale[5] / 2)} ${rem(scale[3] / 2)};
      min-width: 20rem;
    }

    > li + li {
      margin-top: ${rem(scale[5] / 2)};
    }
  `};

  @supports (display: grid) {
    display: grid;
    justify-items: center;
    grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
    grid-gap: ${rem(scale[3])};

    > li {
      width: 100%;
    }

    > li + li {
      margin-top: 0;
    }

    ${media.mobile`
      margin: 0;

      > li {
        margin: 0;
        min-width: 0;
      }
    `};

    ${media.tablet`
      grid-gap: ${rem(scale[5])} ${rem(scale[3])};
      grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
    `};

    ${({compact}) =>
      compact &&
      css`
        grid-template-columns: repeat(auto-fill, minmax(10rem, 1fr));
        grid-gap: ${rem(scale[2])};

        ${media.tablet`
          grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
          grid-gap: ${rem(scale[4])} ${rem(scale[2])};
        `};
      `}
  }
`

const listVariants = {
  enter: {transition: {staggerChildren: 0.05}},
}

export default function ItemGrid({data = [], compact, render}) {
  return (
    <Wrapper>
      <GridWithFallback compact={compact} variants={listVariants}>
        {data.map(render)}
      </GridWithFallback>
    </Wrapper>
  )
}
