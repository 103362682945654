import React from 'react'
import styled from 'styled-components/macro'
import {rem} from 'polished'
import {motion, useSpring} from 'framer-motion'
import {ColorPresence, color, scale} from 'core/theme'

const StyledLink = styled.a`
  text-decoration: none;
  color: white;
  display: block;
  margin-left: ${rem(scale[3])};
  padding: 0;
  &:hover {
    text-decoration: none;
  }
`

export default function ShopLink({translate}) {
  const {color: presenceColor} = React.useContext(ColorPresence)
  const circleColor = useSpring(color.brand.yellow, {damping: 50})

  React.useEffect(() => {
    return presenceColor.onChange(latestpresenceColor => {
      if (latestpresenceColor === 'rgba(247, 213, 72, 1)' /* brand.yellow */) {
        circleColor.set(color.brand.blue)
      } else {
        circleColor.set(color.brand.yellow)
      }
    })
  }, [presenceColor, circleColor])

  return (
    <StyledLink
      href='https://foodunion.com'
      target='_self'
      title={translate('Iet uz FoodUnion.com')}
    >
     EN
    </StyledLink>
  )
}
