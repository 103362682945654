export const color = {
  white: '#FFFFFF',
  black: '#000000',

  brand: {
    blue: '#54B1D0',
    deepBlue: '#31589F',
    green: '#ACCE42',
    purple: '#503578',
    yellow: '#F7D548',
  },

  /* FIXME: Replace color.text with color.brand */
  text: {
    default: '#31589F',
    green: '#ACCE42',
    blue: '#54B1D0',
  },
}

export function getThemeColor(theme) {
  const value = color.brand[theme]
  return value || color.white
}
