import React from 'react'
import styled from 'styled-components/macro'
import {rem} from 'polished'
import {motion} from 'framer-motion'
import {media, scale} from 'core/theme'

const size = [180, 280]

const Wrapper = styled(motion.div)`
  position: relative;
  text-align: center;
  width: ${rem(size[0])};

  ${media.tablet`
    width: ${rem(size[1])};
  `};
`

const ImageWrapper = styled.div`
  border-radius: 50%;

  width: ${rem(size[0])};
  height: ${rem(size[0])};

  ${media.tablet`
    width: ${rem(size[1])};
    height: ${rem(size[1])};
  `};
`

const Image = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 50%;
`

const ContentWrapper = styled.div`
  h4 {
    font-size: ${rem(15)};
    margin-top: ${rem(scale[1])};
    margin-bottom: ${rem(scale[1])};
  }

  p {
    font-size: ${rem(15)};
    margin: 0;
  }

  ${media.tablet`
    h4 {
      font-size: ${rem(18)};
      margin-top: ${rem(scale[2])};
    }

    p {
      font-size: ${rem(18)};
    }
  `};
`

export default function PersonImage(props) {
  const {name, position, image} = props

  const motion = {
    initial: props.initial,
    animate: props.animate,
    variants: props.variants,
  }

  return (
    <Wrapper {...motion}>
      <ImageWrapper>
        <Image src={image.src} srcSet={image.srcSet} sizes='280px' alt='' />
      </ImageWrapper>
      <ContentWrapper>
        <h4>{name}</h4>
        <p>{position}</p>
      </ContentWrapper>
    </Wrapper>
  )
}
