import styled from 'styled-components/macro'
import {media} from 'core/theme'
import Heading from './Heading'

const HeadingMobile = styled(Heading)`
  ${media.tablet`
    display: none;
  `};
`

export default HeadingMobile
