import React from 'react'
import {motion} from 'framer-motion'

const variants = {
  initial: {opacity: 0},
  enter: {opacity: 1, transition: {duration: 0.4, when: 'beforeChildren'}},
  exit: {
    opacity: 0,
    transition: {duration: 0.2, when: 'afterChildren'},
  },
}

export default function PageTransition({as, children}) {
  const Element = as ? motion[as] : motion.div
  return (
    <Element
      as={as}
      initial='initial'
      animate='enter'
      exit='exit'
      variants={variants}
      style={{flex: 1}}
    >
      {children}
    </Element>
  )
}
