import styled, {css} from 'styled-components/macro'
import {rem} from 'polished'
import {measurements, media, scale} from 'core/theme'

export const SiteWidth = styled.div`
  margin-right: auto;
  margin-left: auto;
  max-width: ${rem(measurements.siteWidth)};
`

export const ContentWidth = styled.div`
  flex: 1;
  margin-right: auto;
  margin-left: auto;
  max-width: ${rem(measurements.contentWidth)};
  padding-right: ${rem(scale[2])};
  padding-left: ${rem(scale[2])};

  ${({overflowHidden}) =>
    overflowHidden &&
    css`
      overflow-x: hidden;
    `};

  ${media.tablet`
    padding-right: ${rem(scale[4])};
    padding-left: ${rem(scale[4])};
  `};
`
