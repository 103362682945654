import React from 'react'
import {BrowserRouter as Router, Route} from 'react-router-dom'
import {Helmet} from 'react-helmet'
import {AnimatePresence} from 'framer-motion'
import {ThemeProvider, ColorPresenceProvider} from 'core/theme'
import {Loader, NavBarConnected, Footer, CookieConsent} from 'modules'
import {LanguageProvider, LanguageContext} from 'core/language'
import RouteConfig from 'core/RouteConfig'
import ScrollToTop from 'pages/components/ScrollToTop'

// import ScrollRestore from 'pages/components/ScrollRestore'

function HelmetWithLang() {
  const {activeLang} = React.useContext(LanguageContext)
  const title =
    activeLang === 'en'
      ? 'Official website Food Union'
      : 'Food Union oficiālā vietne'
  const description =
    activeLang === 'en'
      ? 'Пионер молочной промышленности с 1909 года'
      : 'Piensaimniecības nozares vadošā loma kopš 1909. gada'
  return (
    <Helmet defaultTitle={title} titleTemplate={`%s | ${title}`}>
      <meta name='description' content={description} />
    </Helmet>
  )
}

function App() {
  return (
    <ThemeProvider>
      <Router>
        {/* FIXME: Problems with content layout and
            section offset, background color presence.
          <ScrollRestore />
        */}

        <LanguageProvider>
          <ColorPresenceProvider>
            <NavBarConnected />
            <HelmetWithLang />

            <React.Suspense fallback={<Loader />}>
              <Route
                render={({location}) => (
                  <>
                    <ScrollToTop />

                    <AnimatePresence
                      exitBeforeEnter
                      // FIXME: Not triggered
                      // onExitComplete={() => {
                      //   window.scrollTo(0, 0)
                      // }}
                    >
                      <RouteConfig location={location} />
                    </AnimatePresence>
                  </>
                )}
              />
            </React.Suspense>

            <Footer />
            <CookieConsent />
          </ColorPresenceProvider>
        </LanguageProvider>
      </Router>
    </ThemeProvider>
  )
}

export default App
