import {css} from 'styled-components/macro'
import {breakpoints} from './tokens/layout'
import {sortEntriesByValue} from './useResponsiveValue'

export const media = sortEntriesByValue(breakpoints).reduce(
  (map, [key, width]) => {
    map[key] = (...args) => css`
      @media screen and (min-width: ${width / 16}em) {
        ${css(...args)}
      }
    `
    return map
  },
  {}
)
