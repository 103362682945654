import React from 'react'
import styled from 'styled-components/macro'
import {rem} from 'polished'
import {NavLink} from 'react-router-dom'
import {color, media, scale, breakpoints, ColorPresence} from 'core/theme'
import {useMatchMedia} from 'core/hooks'
import {ContentWidth, ExternalLink} from 'elements'
import {Icon} from 'modules/Article/elements'

const StyledFooter = styled.footer`
  background-color: ${color.text.default};
  color: ${color.white};
  font-size: ${rem(15)};
  overflow: hidden;
  padding: ${rem(scale[3])} 0 ${rem(scale[2])};
  position: relative;

  a {
    color: inherit;
    display: inline-block;
    text-decoration: none;

    &[rel]:hover {
      text-decoration: underline;
    }
  }

  p {
    margin: 0;
  }

  ${media.tablet`
    padding: ${rem(scale[3])} 0;
  `};
`

const StyledNav = styled.nav`
  margin-bottom: 1em;

  ul {
    flex-direction: row;
    flex-wrap: wrap;
    margin: ${rem(-scale[1])};
  }

  li {
    margin: ${rem(scale[1])};
  }

  a {
    color: inherit;
    margin: -0.5em;
    padding: 0.5em;
    text-decoration: none;

    &.active,
    &:hover {
      text-decoration: underline;
    }
  }

  ${media.tablet`
    margin-bottom: 0;

    ul {
      margin: ${rem(-scale[1])} ${rem(-scale[2])};
    }

    li {
      margin: ${rem(scale[1])} ${rem(scale[2])};
    }
  `};
`

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 ${rem(-scale[1])};

  ${media.tablet`
    margin: 0 ${rem(-scale[2])};
  `};
`

const Column = styled.div`
  margin: 0 ${rem(scale[1])};

  ${media.tablet`
    margin: 0 ${rem(scale[2])};
  `};
`

const InlineColumn = styled.div`
  display: flex;
  align-items: center;
  margin: 0 ${rem(scale[1])};

  ${media.tablet`
    margin: 0 ${rem(scale[2])};
  `};
`

const MobileRowBottom = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin: 0 ${rem(-scale[1])};
`

const Copyright = styled.p`
  white-space: nowrap;

  ${media.tablet`
    text-align: right;
  `};
`

const SocialList = styled.ul`
  flex-direction: row;

  a {
    display: block;
    padding: 0;

    svg {
      width: ${rem(49)};
      height: ${rem(49)};

      path {
        fill: ${color.white};
      }
    }
  }

  ${media.tablet`
    margin-left: ${rem(scale[2])};
  `};
`

const pages = [
  {
    slug: 'about-us',
    title: 'About Us',
  },
  {
    slug: 'media-kit',
    title: 'Media Kit',
  },
  {
    slug: 'privacy-policy',
    title: 'Privacy Policy',
  },
]

export default function Footer() {
  const matchTablet = useMatchMedia(breakpoints.tablet)

  const {color: colorPresence, saturation} = React.useContext(ColorPresence)

  const resetColors = React.useCallback(() => {
    colorPresence.set('#ffffff')
    saturation.set(0)
  }, [colorPresence, saturation])

  const mainNav = React.useMemo(
    () => (
      <StyledNav>
        <ul>
          {pages.map(page => (
            <li key={page.slug}>
              <NavLink to={`/${page.slug}`} onClick={() => resetColors()}>
                {page.title}
              </NavLink>
            </li>
          ))}
        </ul>
      </StyledNav>
    ),
    [resetColors]
  )

  const socialLinks = React.useMemo(() => {
    const links = [
      {
        icon: 'facebook',
        title: 'Facebook',
        url: 'https://www.facebook.com/FoodUnion',
      },
      {
        icon: 'instagram',
        title: 'Instagram',
        url: 'https://www.instagram.com/foodunionlv/',
      },
      {
        icon: 'twitter',
        title: 'Twitter',
        url: 'https://twitter.com/FoodUnion',
      },
      {
        icon: 'linkedin',
        title: 'LinkedIn',
        url: 'https://www.linkedin.com/company/food-union-group',
      },
      {
        icon: 'youtube',
        title: 'YouTube',
        url: 'https://www.youtube.com/user/FoodUnion',
      },
    ]
    return (
      <SocialList>
        {links.map(({title, url, icon}) => (
          <li key={title}>
            <ExternalLink title={title} url={url}>
              <Icon id={icon} />
            </ExternalLink>
          </li>
        ))}
      </SocialList>
    )
  }, [])

  const copyright = React.useMemo(() => {
    const currentYear = new Date().getFullYear()
    return <Copyright>{`© Food Union Latvia ${currentYear}`}</Copyright>
  }, [])

  if (matchTablet) {
    return (
      <StyledFooter>
        <ContentWidth>
          <Flex>
            <Column>{mainNav}</Column>
            <Flex>
              <InlineColumn>
                {copyright}
                {socialLinks}
              </InlineColumn>
            </Flex>
          </Flex>
        </ContentWidth>
      </StyledFooter>
    )
  }

  return (
    <StyledFooter>
      <ContentWidth>
        <Flex>
          <Column>{mainNav}</Column>
        </Flex>
        <MobileRowBottom>
          <Column>{copyright}</Column>
          <Column>{socialLinks}</Column>
        </MobileRowBottom>
      </ContentWidth>
    </StyledFooter>
  )
}
