import {css} from 'styled-components/macro'
import {rem} from 'polished'
import {scale} from '../tokens/layout'
import {media} from '../utils'

const headingBase = css`
  line-height: 130%;
`

const primaryHeading = css`
  ${headingBase};
  font-size: ${rem(scale[5])};
`

const secondaryHeading = css`
  ${headingBase};
  font-size: ${rem(scale[4])};
`

const textBase = css`
  font-size: ${rem(15)};
  line-height: 150%;

  ${media.tablet`
    font-size: ${rem(18)};
  `};
`

const leadText = css`
  ${textBase};
  font-weight: 700;
`

const bodyText = css`
  ${textBase};
`

const blockquote = css`
  font-size: ${rem(scale[6])};
  font-weight: 700;
  line-height: 110%;
`

const caption = css`
  font-size: ${rem(15)};
  font-style: italic;
`

const small = css`
  font-size: ${rem(12)};
`

export const type = {
  primaryHeading,
  secondaryHeading,
  leadText,
  bodyText,
  blockquote,
  caption,
  small,
}
