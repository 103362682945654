import React from 'react'
import styled from 'styled-components/macro'
import {rem} from 'polished'

const StyledButton = styled.button.attrs({
  type: 'button',
})`
  cursor: pointer;
  margin: -0.25em;
  padding: 0.25em;

  > * {
    pointer-events: none;
  }

  /* svg {
    width: ${rem(49)};
    height: ${rem(49)};
  } */
`

export default function IconButton({
  onClick,
  title,
  children,
  className,
  style,
}) {
  return (
    <StyledButton
      onClick={onClick}
      title={title}
      className={className}
      style={style}
    >
      {children}
    </StyledButton>
  )
}
