import styled, {css} from 'styled-components/macro'
import {rem} from 'polished'
import {media} from 'core/theme'

const Heading = styled.h1`
  font-size: ${rem(34)};
  line-height: 120%;
  margin-bottom: 1em;

  ${({center}) =>
    center &&
    css`
      text-align: center;
    `};

  ${media.tablet`
    font-size: ${rem(41)};
    line-height: 130%;
  `};
`

export default Heading
