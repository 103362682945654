import React from 'react'
import styled from 'styled-components/macro'

const StyledLink = styled.a`
  display: inline-flex;
  align-items: center;
  color: inherit;
  text-decoration: none;

  > i {
    flex: 0;
  }

  &:hover {
    text-decoration: underline;
  }
`

export default function ExternalLink({
  url,
  target,
  download,
  text,
  title,
  children,
  onMouseEnter,
  onMouseLeave,
  className,
}) {
  return (
    <StyledLink
      href={url}
      target={target}
      download={download}
      title={title}
      rel='noopener noreferrer'
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={className}
    >
      {text || children}
    </StyledLink>
  )
}

ExternalLink.defaultProps = {
  target: '_blank',
}
