import React from 'react'
import styled, {keyframes, css} from 'styled-components/macro'
import {rem} from 'polished'
import {motion} from 'framer-motion'
import {color, media, scale} from 'core/theme'

const Wrapper = styled.div`
  margin: ${rem(scale[4])} 0 ${rem(scale[6])};

  ${media.tablet`
    margin: ${rem(scale[6])} 0 ${rem(scale[9])};
  `};
`

const infiniteScroll = keyframes`
  100% {
    transform: translateX(-50%);
  }
`

const SlidingList = styled(motion.div)`
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  overflow-y: hidden;
  margin: ${rem(scale[2])} 0;
  padding: ${rem(scale[2])} ${rem(scale[2])};
  scroll-snap-type: x mandatory;

  > div {
    display: flex;
    animation: ${infiniteScroll} ${({items}) => items * 3}s linear infinite;

    &:hover {
      animation-play-state: paused;
    }
  }

  ${({isStatic}) =>
    isStatic &&
    css`
      justify-content: center;

      > div {
        animation: none;
        flex-wrap: wrap;
        justify-content: center;
      }
    `};

  @media (pointer: fine) {
    ::-webkit-scrollbar {
      display: none;
    }

    /* ::-webkit-scrollbar {
      border-radius: 2px;
      height: 5px;
    }

    ::-webkit-scrollbar-thumb {
      background-color: ${color.brand.deepBlue};
    }

    ::-webkit-scrollbar-track {
      background-color: #F1F1F1;
    } */
  }

  li {
    list-style: none;
  }

  li + li {
    margin-left: ${rem(scale[2])};
  }

  li:last-child {
    padding-right: ${rem(scale[2])};
  }

  ${media.tablet`
    margin: ${rem(scale[3])} 0;
    padding: ${rem(scale[3])} ${rem(scale[4])};

    li + li {
      margin-left: ${rem(scale[4])};
    }

    li:last-child {
      padding-right: ${rem(scale[4])};
    }
  `};
`

const listVariants = {
  enter: {transition: {staggerChildren: 0.05}},
}

export default function SlidingItemList({data = [], render}) {
  const isStatic = data.length < 4

  const dataWithClones = React.useMemo(() => {
    if (data) {
      const origins = data.map(item => ({...item, itemKey: item.slug}))

      if (isStatic) {
        return [...origins]
      }

      const clones = data.map(item => ({
        ...item,
        itemKey: item.slug + '_clone',
      }))
      return [...origins, ...clones]
    }
  }, [data, isStatic])

  return (
    <Wrapper>
      <SlidingList
        variants={listVariants}
        items={data.length}
        isStatic={isStatic}
      >
        <div>{dataWithClones.map(render)}</div>
      </SlidingList>
    </Wrapper>
  )
}
