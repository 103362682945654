import React from 'react'
import styled, {css} from 'styled-components/macro'
import {rem} from 'polished'
import {motion} from 'framer-motion'
import {color, media, scale} from 'core/theme'

const List = styled(motion.ul)`
  flex-direction: row;
  flex-wrap: wrap;
  margin: ${rem(-scale[2])};
  margin-bottom: ${rem(scale[3])};

  > li {
    padding: ${rem(scale[2])};
    text-align: left;
  }

  ${({center}) => center && css`
    justify-content: center;
  `};

  ${media.tablet`
    margin: ${rem(-scale[4])} ${rem(-scale[1])};
    margin-bottom: ${rem(scale[4])};

    > li {
      flex: 0 1 50%;
      max-width: 50%;
      padding: ${rem(scale[4])} ${rem(scale[1])};
    }
  `};
`

const Fact = styled.div`
  max-width: 25ch;

  h4 {
    font-size: ${rem(23)};
    line-height: 110%;
    margin-top: 0;
    margin-bottom: ${rem(scale[1])};
  }

  p {
    font-size: ${rem(15)};
    line-height: 110%;
    margin: 0;
  }

  ${media.tablet`
    max-width: none;

    h4, p {
      max-width: 75%;
      margin-left: auto;
      margin-right: auto;
    }

    h4 {
      font-size: ${rem(41)};
      margin-bottom: ${rem(scale[2])};
    }

    p {
      font-size: ${rem(27)};
    }
  `};

  ${({highlighted}) =>
    highlighted &&
    css`
      color: ${color.text.green};
    `};
`

const listVariants = {
  enter: {transition: {staggerChildren: 0.075, delayChildren: 0.4}},
}

const itemVariants = {
  initial: {x: 50, opacity: 0},
  enter: {
    x: 0,
    opacity: 1,
    transition: {type: 'spring', damping: 20, stiffness: 200},
  },
}

export default function FactList({center, facts = []}) {
  return (
    <List animate='enter' variants={listVariants} center={center}>
      {facts && facts.map(({value, label, highlighted}, i) => (
        <motion.li key={`fact_${i + 1}`} variants={itemVariants}>
          <Fact highlighted={highlighted}>
            <h4>{value}</h4>
            <p>{label}</p>
          </Fact>
        </motion.li>
      ))}
    </List>
  )
}
