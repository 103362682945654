import React from 'react'
import styled from 'styled-components/macro'
import {styleBase} from './ActionLink'

const StyledButton = styled.button.attrs({
  type: 'button',
})`
  ${styleBase};
`

export default function ActionButton({
  onClick,
  title,
  children,
  className,
  style,
  as,
  ...props
}) {
  return (
    <StyledButton
      onClick={onClick}
      title={title}
      className={className}
      style={style}
      as={as}
      {...props}
    >
      {children}
    </StyledButton>
  )
}
