import React from 'react'
import styled from 'styled-components/macro'
import {useLocation, useHistory} from 'react-router-dom'
import {rem} from 'polished'
import {Link} from 'react-router-dom'
import {motion, useTransform} from 'framer-motion'
import {ColorPresence, media, scale} from 'core/theme'

const Wrapper = styled(motion.div)`
  display: flex;
  align-items: center;
`

const StyledLink = styled(Link)`
  color: inherit;
  margin: -0.75em;
  margin-left: ${rem(scale[2])};
  padding: 0.75em;
  text-decoration: none;
  text-transform: uppercase;

  ${media.content`
    margin-left: ${rem(scale[4])};
  `};
`

export default function LangNav({activeLang, defaultLang, to}) {
  const {saturation} = React.useContext(ColorPresence)
  const textColor = useTransform(saturation, [0, 1], ['#31589F', '#ffffff'])

  const {homeLink, lang} = to

  const history = useHistory()
  const {pathname} = useLocation()

  return (
    <Wrapper style={{color: textColor}}>
      <StyledLink
        to={homeLink}
        onClick={event => {
          event.preventDefault()

          let path = pathname.split('/')
          path = activeLang === defaultLang ? path[1] : path[2]
          if (path) {
            fetch(`/api/pages/${path}/${activeLang}`)
              .then(res => res.json())
              .then(data => {
                const translatedPageId =
                  data &&
                  data[0] &&
                  data[0].translations &&
                  data[0].translations[lang]

                if (translatedPageId) {
                  fetch(`/api/all-pages/${lang}`)
                    .then(res => res.json())
                    .then(data => {
                      const translatedPage =
                        data && data.find(page => page.id === translatedPageId)
                      if (translatedPage) {
                        if (homeLink === '/') {
                          history.push({
                            pathname: `/${translatedPage.slug}`,
                            state: {
                              pageData: data,
                            },
                          })
                        } else {
                          history.push({
                            pathname: `${homeLink}/${translatedPage.slug}`,
                            state: {
                              pageData: data,
                            },
                          })
                        }
                      } else {
                        history.push({
                          pathname: homeLink,
                        })
                      }
                    })
                } else {
                  history.push({
                    pathname: homeLink,
                  })
                }
              })
          } else {
            history.push({
              pathname: homeLink,
            })
          }
        }}
      >
        {lang}
      </StyledLink>
    </Wrapper>
  )
}
